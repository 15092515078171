import './App.css';
import React, { useState, useEffect } from 'react'
import Box from '@mui/material/Box';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import { Trades } from './Trades/Trades';
import { Orders } from './Orders/Orders';
import { Accounts } from './Accounts/Accounts';
import { Charts } from './Charts/Charts';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ListAltIcon from '@mui/icons-material/ListAlt';
import CandlestickChartIcon from '@mui/icons-material/CandlestickChart';
import HistoryIcon from '@mui/icons-material/History';
import ImportExportIcon from '@mui/icons-material/ImportExport';
import { History } from './History/History';
import Localbase from 'localbase'
import io from "socket.io-client";
/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';
/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';
/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
// import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';
import Desktop from './Desktop/Desktop'
function App(props) {
  const [isMacOrWindows, setIsMacOrWindows] = useState(false);
  const [value, setValue] = React.useState(4);
  const [AccountDetails, setAccountDetails] = useState("")
  const [OrderProfit, setOrderProfit] = useState("")
  const [OHLC, setOHLC] = useState("")
  const [Quote, setQuote] = useState("")
  const [Update, setUpdate] = useState("")
  const [variable, setVariable] = useState(0);
  const db = new Localbase('MultiverseFunding');
  const [CurrentPair, setCurrentPair] = useState("EURUSD");
  function convertProfitToNumber(account) {
    // Create a deep copy of the account object to avoid mutating the original object
    const accountCopy = JSON.parse(JSON.stringify(account));
    // Check if the 'openPositions' array exists and convert the 'profit' string to a number
    if (accountCopy.openPositions && Array.isArray(accountCopy.openPositions)) {
      accountCopy.openPositions.forEach(position => {
        if (position.profit && typeof position.profit === 'string') {
          position.profit = parseFloat(position.profit);
        }
      });
    }
    return accountCopy;
  }
  let SOCKET_URL;
  if (process.env.NODE_ENV !== 'development') {
    SOCKET_URL = "https://multitradefx-58ae1ffb4e56.herokuapp.com";
  } else {
    // SOCKET_URL = "http://localhost:3000";
    SOCKET_URL = "https://multitradefx-58ae1ffb4e56.herokuapp.com";
  }

  useEffect(() => {
    let id;
    const fetchDataAndSetupSocket = async () => {
      try {
        const users = await db.collection('tradeAccount').get();
        id = users[0].id
      } catch (error) {
        console.error("Error fetching data:", error);
      }
      const socket = io(`${SOCKET_URL}/${id}`, {
        autoConnect: true,
        reconnection: true,
        reconnectionAttempts: Infinity,
        reconnectionDelay: 1000,
        reconnectionDelayMax: 5000,
      });
      // Listening for the 'welcome' event from the server
      socket.on('userLivedata', (data) => {
        if (data !== null) {
          setOrderProfit(data);
          setUpdate(convertProfitToNumber(data))
        }
      });
      // Listening for the 'welcome' event from the server
      socket.on('dataFeed', (data) => {
        setQuote(data);
        setOHLC(data);
      });
      return () => socket.disconnect();
    };
    fetchDataAndSetupSocket();

    var userAgent = navigator.userAgent || navigator.vendor || window.opera;


    if (/android/i.test(userAgent)) {
        return setIsMacOrWindows(false);;
    }
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        return setIsMacOrWindows(false);;
    }else{
      setIsMacOrWindows(true);
      setValue(5);
    }

    // setIsMacOrWindows(false);

    // const userAgent = navigator.userAgent;
    // const platform = window.navigator.platform;
    // if (platform.indexOf("Win") !== -1) {
    //   setIsMacOrWindows(true);
    //   setValue(5);
    // } else if (platform.indexOf("Mac") !== -1) {
    //   setIsMacOrWindows(true);
    //   setValue(5);
    // } else if (platform.indexOf("Linux") !== -1) {
    //   setIsMacOrWindows(true);
    //   setValue(5);
    // } else if (/Android/i.test(userAgent)) {
    //   setIsMacOrWindows(false);
    // } else {
    //   setIsMacOrWindows(false);
    // }
  }, [variable]);

  const handleDataFromChild = (childData) => {
    setValue(childData);
  };
  // Function to update the state
  const updatePair = newData => {
    setCurrentPair(newData);
  };


  return (
    <div className='main-container'>
      {value == 4 ? <Accounts onLoginStatus={(data) => { setVariable(data) }} AccountDetails={AccountDetails} /> : ""}
      {value == 0 ? <Trades sendDataToParent={handleDataFromChild} CurrentPair={CurrentPair} updatePair={updatePair} Quote={Quote} /> : ""}
      {value == 3 ? <History Update={Update} AccountDetails={AccountDetails} /> : ""}
      {value == 2 ? <Orders sendDataToParent={handleDataFromChild} updatePair={updatePair} Quote={Quote} Update={Update} value={value} /> : ""}
      {value == 1 ? <Charts CurrentPair={CurrentPair} Quote={Quote} Update={Update} OHLC={OHLC} value={value} /> : ""}
      {isMacOrWindows ? <Desktop Quote={Quote} AccountDetails={Update} CurrentPair={CurrentPair} Update={Update} OHLC={OHLC} value={value} /> : <Box className="bottom-navigation">
        <BottomNavigation showLabels value={value} onChange={(event, newValue) => { setValue(newValue); }}>
          <BottomNavigationAction label="Quotes" icon={<ImportExportIcon />} />
          <BottomNavigationAction label="Chart" icon={<CandlestickChartIcon />} />
          <BottomNavigationAction label="Trades" icon={<ListAltIcon />} />
          <BottomNavigationAction label="History" icon={<HistoryIcon />} />
          <BottomNavigationAction label="Accounts" icon={<AccountCircleIcon />} />
        </BottomNavigation>
      </Box>}

    </div>

  );
}
export default App;